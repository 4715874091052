
    <template>
      <section class="content element-doc">
        <h2>Image 图片</h2>
<p>图片容器，在保留原生 img 的特性下，支持懒加载，自定义占位、加载失败等</p>
<h3>基础用法</h3>
<demo-block>
        <div><p>可通过<code>fit</code>确定图片如何适应到容器框，同原生 <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit">object-fit</a>。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-image&quot;&gt;
    &lt;div class=&quot;block&quot; v-for=&quot;fit in fits&quot; :key=&quot;fit&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;{{ fit }}&lt;/span&gt;
      &lt;el-image
        style=&quot;width: 100px; height: 100px&quot;
        :src=&quot;url&quot;
        :fit=&quot;fit&quot;
      &gt;&lt;/el-image&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      let state = reactive({
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        url:
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
      })
      return { ...toRefs(state) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>占位内容</h3>
<demo-block>
        <div><p>可通过<code>v-slot:placeholder</code>可自定义占位内容</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-image__placeholder&quot;&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;默认&lt;/span&gt;
      &lt;el-image :src=&quot;src&quot;&gt;&lt;/el-image&gt;
    &lt;/div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;自定义&lt;/span&gt;
      &lt;el-image :src=&quot;src&quot;&gt;
        &lt;template #placeholder&gt;
          &lt;div class=&quot;image-slot&quot;&gt;加载中&lt;span class=&quot;dot&quot;&gt;...&lt;/span&gt;&lt;/div&gt;
        &lt;/template&gt;
      &lt;/el-image&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      let src = ref(
        'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
      )
      return { src }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>加载失败</h3>
<demo-block>
        <div><p>可通过<code>v-slot:error</code>可自定义加载失败内容</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-image__error&quot;&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;默认&lt;/span&gt;
      &lt;el-image&gt;&lt;/el-image&gt;
    &lt;/div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;自定义&lt;/span&gt;
      &lt;el-image&gt;
        &lt;template #error&gt;
          &lt;div class=&quot;image-slot&quot;&gt;
            &lt;i class=&quot;el-icon-picture-outline&quot;&gt;&lt;/i&gt;
          &lt;/div&gt;
        &lt;/template&gt;
      &lt;/el-image&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>懒加载</h3>
<demo-block>
        <div><p>可通过<code>lazy</code>开启懒加载功能，当图片滚动到可视范围内才会加载。可通过<code>scroll-container</code>来设置滚动容器，若未定义，则为最近一个<code>overflow</code>值为<code>auto</code>或<code>scroll</code>的父元素。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-image__lazy&quot;&gt;
    &lt;el-image v-for=&quot;url in urls&quot; :key=&quot;url&quot; :src=&quot;url&quot; lazy&gt;&lt;/el-image&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      let state = reactive({
        urls: [
          'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
          'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
          'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
          'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
          'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
          'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
          'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
        ]
      })
      return { ...toRefs(state) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>大图预览</h3>
<demo-block>
        <div><p>可通过 <code>previewSrcList</code> 开启预览大图的功能。</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-image__preview&quot;&gt;
    &lt;el-image
      style=&quot;width: 100px; height: 100px&quot;
      :src=&quot;url&quot;
      :preview-src-list=&quot;srcList&quot;
    &gt;
    &lt;/el-image&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      let state = reactive({
        url:
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        srcList: [
          'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
          'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
        ]
      })
      return { ...toRefs(state) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>src</td>
<td>图片源，同原生</td>
<td>string</td>
<td>—</td>
<td>-</td>
</tr>
<tr>
<td>fit</td>
<td>确定图片如何适应容器框，同原生 <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit">object-fit</a></td>
<td>string</td>
<td>fill / contain / cover / none / scale-down</td>
<td>-</td>
</tr>
<tr>
<td>alt</td>
<td>原生 alt</td>
<td>string</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>referrer-policy</td>
<td>原生 referrerPolicy</td>
<td>string</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>lazy</td>
<td>是否开启懒加载</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>scroll-container</td>
<td>开启懒加载后，监听 scroll 事件的容器</td>
<td>string / HTMLElement</td>
<td>—</td>
<td>最近一个 overflow 值为 auto 或 scroll 的父元素</td>
</tr>
<tr>
<td>preview-src-list</td>
<td>开启图片预览功能</td>
<td>Array</td>
<td>—</td>
<td>-</td>
</tr>
<tr>
<td>z-index</td>
<td>设置图片预览的 z-index</td>
<td>Number</td>
<td>—</td>
<td>2000</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>load</td>
<td>图片加载成功触发</td>
<td>(e: Event)</td>
</tr>
<tr>
<td>error</td>
<td>图片加载失败触发</td>
<td>(e: Error)</td>
</tr>
</tbody>
</table>
<h3>Slots</h3>
<table>
<thead>
<tr>
<th>名称</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>placeholder</td>
<td>图片未加载的占位内容</td>
</tr>
<tr>
<td>error</td>
<td>加载失败的内容</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createVNode: _createVNode, resolveComponent: _resolveComponent } = Vue

function render (_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createBlock("div", { class: "demo-image" }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fits, (fit) => {
      return (_openBlock(), _createBlock("div", {
        class: "block",
        key: fit
      }, [
        _createVNode("span", { class: "demonstration" }, _toDisplayString(fit), 1),
        _createVNode(_component_el_image, {
          style: {"width":"100px","height":"100px"},
          src: _ctx.url,
          fit: fit
        }, null, 8, ["src", "fit"])
      ]))
    }), 128))
  ]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      let state = reactive({
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        url:
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
      })
      return { ...toRefs(state) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, createTextVNode: _createTextVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createBlock("div", { class: "demo-image__placeholder" }, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "默认"),
      _createVNode(_component_el_image, { src: _ctx.src }, null, 8, ["src"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "自定义"),
      _createVNode(_component_el_image, { src: _ctx.src }, {
        placeholder: _withCtx(() => [
          _createVNode("div", { class: "image-slot" }, [
            _createTextVNode("加载中"),
            _createVNode("span", { class: "dot" }, "...")
          ])
        ]),
        _: 1
      }, 8, ["src"])
    ])
  ]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      let src = ref(
        'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
      )
      return { src }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createBlock("div", { class: "demo-image__error" }, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "默认"),
      _createVNode(_component_el_image)
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "自定义"),
      _createVNode(_component_el_image, null, {
        error: _withCtx(() => [
          _createVNode("div", { class: "image-slot" }, [
            _createVNode("i", { class: "el-icon-picture-outline" })
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, resolveComponent: _resolveComponent, createVNode: _createVNode } = Vue

function render (_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createBlock("div", { class: "demo-image__lazy" }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.urls, (url) => {
      return (_openBlock(), _createBlock(_component_el_image, {
        key: url,
        src: url,
        lazy: ""
      }, null, 8, ["src"]))
    }), 128))
  ]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      let state = reactive({
        urls: [
          'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
          'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
          'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
          'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
          'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
          'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
          'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
        ]
      })
      return { ...toRefs(state) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createBlock("div", { class: "demo-image__preview" }, [
    _createVNode(_component_el_image, {
      style: {"width":"100px","height":"100px"},
      src: _ctx.url,
      "preview-src-list": _ctx.srcList
    }, null, 8, ["src", "preview-src-list"])
  ]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      let state = reactive({
        url:
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        srcList: [
          'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
          'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
        ]
      })
      return { ...toRefs(state) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  